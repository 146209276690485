import React, {useEffect} from "react";
import block from "bem-css-modules";

import video from "../../styles/components/Herovideo.module.scss";

export default function HeroVideo({source}) {

  block.setSettings({
    modifierDelimiter: "--",
  });

  const media = block(video);

  useEffect(() => {
    document.getElementById('bannerVideo').play();

    var autoPlayVideo = document.getElementById("bannerVideo");
    autoPlayVideo.oncanplaythrough = function() {
        autoPlayVideo.muted = true;
        autoPlayVideo.play();
        autoPlayVideo.pause();
        autoPlayVideo.play();
    }

  })

  return (
      <section className={media("")}>
        <div className={media("wrapper")}>
          <video   id="bannerVideo" autoplay="autoplay" muted="muted" loop="loop" playsinline="playsinline" preload="auto" data-aos="fade-up" className={`${media("video")}`}>
            <source src={source} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </section>
  )
}